import './App.css';
import TonWalletChecker from './pages/crypto/ton/checker';

function App() {
  return (
    <div className="App">
      <TonWalletChecker/>
    </div>
  );
}

export default App;
